import Modal from 'react-modal';
import { useState } from 'react';
import '@splidejs/splide/css';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');



export function Card({ item }) {
  const [hover, setHover] = useState(false);
  const Navigate = useNavigate();

  const openModal = () => {
    Navigate(`/product/${item.id}`);
  };

  return (
    <div className='col-6 col-md-6' onClick={openModal}>
      <div
        className='card-image'
        style={{
          backgroundImage: `url(${!hover ? item.mainphoto : item.backphoto})`,
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={openModal}
      ></div>

      <div className='card-details'>
        <div className='card-title'>{item.name}</div>
        <div className='card-price'>
          {item.category === 'SOON'
            ? 'Pre-order now'
            : item.soldout
            ? 'Sold out'
            : item.price + ' RUB'}
        </div>
      </div>
    </div>
  );
}
