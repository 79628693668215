import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useCartStore } from '../../store/cart';
import { useNavigate } from 'react-router-dom';

//components
import { CDEKwidget } from './CDEKwidget';

//govnocode by o77777o
import {
  saveUserToLS,
  getFullNameFromLS,
  getPhoneFromLS,
  getTelegramFromLS,
  getEmailFromLS,
  getDeliveryFromLS,
} from '../../store/user';

const API_URL = process.env.REACT_APP_API_URL;

const customStyles = {
  overlay: {
    zIndex: '11',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '0',
    inset: '0',
  },
};

export function Cart() {
  const { items, remove, clear } = useCartStore();
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState(getPhoneFromLS() || '+79');
  const [fullName, setFullName] = useState(getFullNameFromLS());
  const [telegram, setTelegram] = useState(getTelegramFromLS());
  const [email, setEmail] = useState(getEmailFromLS());
  const [delivery, setDelivery] = useState(getDeliveryFromLS());
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState('');
  // const Navigate = useNavigate();

  const saveUserData = () => {
    saveUserToLS(phone, fullName, telegram, email, delivery);
  };

  const getAmount = () => {
    const sum = items.reduce((prev, curr) => prev + parseInt(curr.price), 0);
    if (delivery) {
      setAmount(sum + delivery.tariff.sum);
      console.log(delivery);
      return;
    }

    setAmount(sum);
  };

  useEffect(() => {
    getAmount();
  }, [items, delivery]);

  const processCheckout = () => {
    //TODO add fio cdek
    saveUserData();

    if (!items.length) {
      setError('Добавьте товар');
      return;
    }

    if (!delivery) {
      setError('Выберите способ доставки');
      return;
    }

    if (!phone.includes('+7') || !phone || phone.length < 10) {
      setError('Введите номер телефона с "+79..."');
      return;
    }

    if (fullName.length < 5) {
      setError('Введите ваши ФИО');
      return;
    }

    if (!telegram.includes('@') || telegram.length <= 3) {
      setError('Введите телеграм для связи c "@"');
      return;
    }

    if (!email.includes('@') || email.length <= 5) {
      setError('Введите корректный email');
      return;
    }

    setError('');

    const request = async () => {
      try {
        const res = await fetch(`${API_URL}/order/new`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order: {
              info: {
                phone,
                fullName,
                telegram,
                email,
                delivery,
              },
              items,
            },
          }),
        });

        if (res.ok) {
          alert('Заказ оформлен!');
          clear();

          return;
        }

        throw new Error(`${res.status} ${res.statusText}`);
      } catch (err) {
        alert(`Что-то пошло не так 😓 попробуем еще раз?`);
      }
    };

    request().catch(console.error);
    // const redirectURL = async () => {
    //   try {
    //     const res = await fetch(`${API_URL}/api/pay`, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({
    //         amount: amount,
    //         email: email,
    //       }),
    //     });

    //     const { url } = await res.json();

    //     if (res.ok) {
    //       window.location.href = url;
    //       clear();
    //       return;
    //     }
    //   } catch (e) {
    //     alert(`Что-то пошло не так 😓 попробуем еще раз?`);
    //   }
    // };
    // redirectURL().catch(console.e);
  };

  const makeCorrectPhone = (e) => {
    let data = [...e.target.value];
    if (data[0] !== '+' || data[1] !== '7' || data[2] !== '9') {
      setPhone('+79');
      return;
    }
    setPhone(e.target.value);
  };

  const makeCorrectTelegram = (e) => {
    let data = [...e.target.value];
    if (data[0] !== '@') {
      setTelegram('@');
      return;
    }
    setTelegram(e.target.value);
  };

  const openModal = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    saveUserData();
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='modal'
      >
        <div className='close-modal' onClick={closeModal}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            fill='currentColor'
            className='bi bi-x-lg'
            viewBox='0 0 16 16'
          >
            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
          </svg>
        </div>

        <div className='shopping-cart'>
          <div className='cart-all'>
            <div className='shopping-cart-title-modal'>Корзина</div>
            {delivery ? (
              <div className='shopping-cart-item'>
                <div className='item-first'>
                  <div>Стоимость доставки</div>
                </div>
                <div className='cart-item-price'>{delivery.tariff.sum} RUB</div>
              </div>
            ) : (
              <></>
            )}
            <div className='cart-items'>
              {items.map((item, key) => (
                <div className='shopping-cart-item' key={key}>
                  <div className='item-first'>
                    <div>{item.name}</div>
                    <div
                      className='cart-btn-remove'
                      onClick={() => {
                        remove(item);
                        getAmount();
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='100%'
                        height='100%'
                        fill='white'
                        viewBox='0 0 16 16'
                      >
                        <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708' />
                      </svg>
                    </div>
                  </div>
                  <div className='cart-item-price'>{item.price} RUB</div>
                  <div className='cart-item-size'>
                    {!item.size ? '' : 'Размер ' + item.size}
                  </div>
                </div>
              ))}
            </div>

            <div className='shopping-cart-title-modal'>TOTAL: {amount} RUB</div>

            <div className='cart-inputs'>
              <div className='input-section'>
                <div className='input-name'>Номер телефона</div>
                <div className='input-info'>для подтверждения заказа</div>
                <input
                  id='phone'
                  value={phone}
                  type='tel'
                  className='cart-input'
                  placeholder='только +7'
                  onChange={(e) => makeCorrectPhone(e)}
                />
              </div>

              <div className='input-section'>
                <div className='input-name'>ФИО получателя</div>
                <div className='input-info'>для офорлмения заказа</div>
                <input
                  value={fullName}
                  type='text'
                  className='cart-input'
                  placeholder='Фамилия Имя Отчество'
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className='input-section'>
                <div className='input-name'>Ник личного профиля в Tелеграм</div>
                <div className='input-info'>
                  чтобы мы могли связаться по уточнению доставки
                </div>
                <input
                  value={telegram}
                  className='cart-input'
                  placeholder='@nickname'
                  onChange={(e) => makeCorrectTelegram(e)}
                />
              </div>

              <div className='input-section'>
                <div className='input-name'>Электронная почта</div>
                <div className='input-info'>
                  чтобы мы отправили электронный чек
                </div>
                <input
                  id='email'
                  value={email}
                  type='email'
                  pattern='.+@example\.com'
                  className='cart-input'
                  placeholder='@email'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className='input-section'>
                <div className='input-name'>Адрес доставки</div>
                {/* <div className='input-info'>выберите ПВЗ CDEK</div> */}
                {delivery ? (
                  <div className='input-info'>
                    доставим:{' '}
                    {delivery.address.doorAddress
                      ? `Курьером. ${delivery.address.doorAddress}`
                      : `ПВЗ. ${delivery.address.city}, ${delivery.address.fullAddress}`}
                  </div>
                ) : (
                  <div className='input-info'>выберите ПВЗ CDEK</div>
                )}
                {delivery ? (
                  <div className='input-info'>
                    доставка: {delivery.tariff.sum} RUB
                  </div>
                ) : (
                  <></>
                )}
                <CDEKwidget deliveryChange={setDelivery} isModalOpen={isOpen} />
              </div>

              {error ? <p className='error'>{error}</p> : <div></div>}
              <button className='cart-btn-checkout' onClick={processCheckout}>
                ОПЛАТИТЬ ЗАКАЗ
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className='cart-modal' onClick={openModal}>
      <svg
        color='white'
        xmlns='http://www.w3.org/2000/svg'
        width='32'
        height='32'
        fill='currentColor'
        className='bi bi-cart'
        viewBox='0 0 16 16'
      >
        <path d='M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2'></path>
      </svg>
      {items.length ? (
        <div className='cart-counter'>{items.length}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
