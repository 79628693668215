const USER = 'user';

export const saveUserToLS = (phone, fullName, telegram, email, delivery) => {
  let user = {
    fullName: fullName,
    phone: phone,
    telegram: telegram,
    email: email,
    delivery: delivery,
  };

  localStorage.setItem(USER, JSON.stringify(user));
};

export const getPhoneFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.phone;
};

export const getFullNameFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.userName;
};

export const getTelegramFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.telegram;
};

export const getEmailFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.email;
};

export const getDeliveryFromLS = () => {
  let user = JSON.parse(localStorage.getItem(USER)) || {};
  return user.delivery;
};
